@import '~leaflet/dist/leaflet.css'; // sass
@import '~react-leaflet-markercluster/dist/styles.min.css'; // sass

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.h-full,
.loading,
.leaflet-container,
#root {
  height: 100%;
}

.notouch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.capitalize {
  text-transform: capitalize;
}

.m-0 {
  margin: 0 !important;
}

.topspacing {
  margin-top: 12px !important;
}

.bold {
  font-weight: bold;
}

.leaflet-popup-content {
  min-width: 25vw;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-collapse-header {
  color: white !important;
  background-color: #1890ff;
}

.text-blue {
  color: #1890ff;
}

.w-full {
  width: 100%;
}

.mt-2 {
  margin-top: 1rem;
}

.search-control-wrap {
  margin-left: 4px;
}

.leaflet-top {
  display: flex;
  flex-direction: column-reverse;
  top: 15px;
}

.leaflet-bar {
  width: fit-content;
  margin-left: 14px !important;
}

.leaflet-control-layers {
  display: none;
}

.ant-list-item {
  padding: 18px 0 !important;
}

.ant-list-item-meta-title {
  margin-bottom: 0px !important;
}

.ant-modal-title h2 {
  margin-bottom: 0px !important;
}

.ant-modal-body {
  padding-top: 4px !important;
}

.flex {
  display: flex !important;
}

.items-center {
  align-items: center;
}
.white-space {
  white-space: break-spaces;
}
